<template>
  <div class="d-inline">
    <!-- <div class="d-none d-print-inline text-center pt-2">{{vpiece}}</div> -->
    <template v-if="piece < 100">
      <div class="d-print-none app_den_odo_tp_b text-center pt-2">
        {{ vpiece }}
      </div>
      <div class="d-none d-print-block">{{ vpiece }}</div>
    </template>
    <template v-else>
      <small>{{ vpiece }}</small>
    </template>
  </div>
</template>

<script>
import { pieceName } from "./tooth-utils";
export default {
  computed: {
    vpiece() {
      return pieceName(this.piece);
    }
  },
  props: {
    piece: {}
  }
};
</script>

<style>
.app_den_odo_tp_b {
  background-image: url("../../assets/odo_status_t_sprite.png");
  background-position: -448px -3px;
  width: 39px;
  height: 39px;
  display: inline-block;
  vertical-align: text-bottom;
}

.app_den_odo_th_text_p {
  position: relative;
  bottom: 0px;
}
</style>
