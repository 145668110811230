<template>
  <Multiselect
    :class="{ invalid: invalid }"
    v-model="val"
    :options="options"
    placeholder="Categoria"
    selectLabel="Seleccionar"
    deselectLabel="Deseleccionar"
    selectedLabel="Seleccionado"
    :loading="isLoading"
    @search-change="find"
    :internal-search="false"
    @open="openHandler"
    :disabled="disabled"
  >
    <template slot="singleLabel" slot-scope="props">{{
      props.option.name
    }}</template>
    <template slot="option" slot-scope="props">{{
      props.option.name
    }}</template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { debounce } from "lodash";
import { GeneralService } from "../../general-module/GeneralService";

export default {
  props: {
    disabled: {
      default: false
    },
    value: {
      default: undefined
    },
    invalid: {
      default: false
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    }
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    reset() {
      this.options = [];
      this.val = null;
    },
    setValueFromId(reg_id) {
      if (reg_id) {
        this.isLoading = true;
        GeneralService.getCategorie(reg_id).then(res => {
          this.isLoading = false;
          this.options = [res];
          this.val = res;
          this.$emit("input", reg_id);
        });
      } else {
        this.val = {};
        // this.$emit("input")
      }
    },
    openHandler() {
      // console.log("open", arguments);
      if (this.options.length == 0) {
        this.getList();
      }
    },
    getList(search) {
      return new Promise(() => {
        GeneralService.getCategories({ search }).then(res => {
          this.options = res.data;
          this.isLoading = false;
          // rsv(res.data)
        });
      });
    },
    setDefault() {},
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      vm.getList(search);
      // .then(list => {
      //   vm.options = list.data;
      //   vm.isLoading = false;
      // });
    }, 350)
  }
};
</script>

<style scoped>
.invalid {
  border: 1px solid red;
  border-radius: 0.25rem;
}
</style>